import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../../components/Button';
import Flex from '../../../components/Flex';
import Select from '../../../components/Select';
import { ReactComponent as BuilderIcon } from '../../../images/icons/builder-overview.svg';
import { ReactComponent as DownloadIcon } from '../../../images/icons/download.svg';
import { ReactComponent as EsriIcon } from '../../../images/icons/esri.svg';
import { ReactComponent as ExcelIcon } from '../../../images/icons/excel.svg';
import { ReactComponent as FigmaIcon } from '../../../images/icons/figma.svg';
import { ReactComponent as InfoIcon } from '../../../images/icons/info-circle.svg';
import { ReactComponent as PowerIcon } from '../../../images/icons/power-bi.svg';

interface Props {
  className?: string;
  onSelect?: (value: string) => void;
  defaultValue: string;
  optionsToExclude?: string[];
}

const LibrariesSelect = ({ className, onSelect, defaultValue, optionsToExclude }: Props) => {
  const navigate = useNavigate();

  const selectOptions = [
    {
      value: 'builder',
      label: (
        <Flex style={{ width: '100%' }} align="center" justify="space-between">
          Builder Overview
        </Flex>
      ),
      icon: <BuilderIcon />,
    },
    {
      value: 'powerBI',
      label: (
        <Flex style={{ width: '100%' }} align="center" justify="space-between">
          Power BI Library
          <Flex gap="8px" align="center">
            <Button
              onPointerDown={() => navigate('/dev-resources/powerbikit')}
              onlyIcon
              variant="plain"
              size="XS"
            >
              <InfoIcon />
            </Button>
            <Button onlyIcon size="XS">
              <DownloadIcon />
            </Button>
          </Flex>
        </Flex>
      ),
      icon: <PowerIcon />,
    },
    {
      value: 'excel',
      label: (
        <Flex style={{ width: '100%' }} align="center" justify="space-between">
          Excel Library
          <Flex gap="8px" align="center">
            <Button
              onPointerDown={() => navigate('/dev-resources/excel-library')}
              onlyIcon
              variant="plain"
              size="XS"
            >
              <InfoIcon />
            </Button>
            <Button onlyIcon size="XS">
              <DownloadIcon />
            </Button>
          </Flex>
        </Flex>
      ),
      icon: <ExcelIcon />,
    },
    {
      value: 'esri',
      label: (
        <Flex style={{ width: '100%' }} align="center" justify="space-between">
          Esri Library
          <Flex gap="8px" align="center">
            <Button
              onPointerDown={() => navigate('/dev-resources/esrikit')}
              onlyIcon
              variant="plain"
              size="XS"
            >
              <InfoIcon />
            </Button>
            <Button onlyIcon size="XS">
              <DownloadIcon />
            </Button>
          </Flex>
        </Flex>
      ),
      icon: <EsriIcon />,
    },
    {
      value: 'figma',
      label: (
        <Flex style={{ width: '100%' }} align="center" justify="space-between">
          Figma Library
          <Flex gap="8px" align="center">
            <Button
              onPointerDown={() => navigate('/dev-resources/figma-library')}
              onlyIcon
              variant="plain"
              size="XS"
            >
              <InfoIcon />
            </Button>
            <Button onlyIcon size="XS">
              <DownloadIcon />
            </Button>
          </Flex>
        </Flex>
      ),
      icon: <FigmaIcon />,
    },
  ];

  const options = selectOptions.filter((option) => !optionsToExclude?.includes(option.value));

  return (
    <Select defaultValue={defaultValue} onSelect={onSelect} className={className} data={options} />
  );
};

LibrariesSelect.defaultProps = {
  className: '',
  onSelect: undefined,
  optionsToExclude: [],
};

const StyledSelect = styled(LibrariesSelect)`
  .select-selected-option {
    button {
        display: none;
    }

    .select-selected-option__icon {
      margin-right: 8px;
      display: flex;
      align-items: center;
    }
  }

  [role='option'] {
    .select-options-item__icon {
      margin-right: 8px;
      display: flex;
      align-items: center;
    }

    .select-options-item__label {
      display: flex;
      justify-content: space-between;
      width: 100%;

      svg {
        > path {
          fill: ${({ theme }) => theme.colors.neutral['text-weak']};
        }
      }
    }
`;

export default StyledSelect;
