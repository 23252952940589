import styled from 'styled-components';

import ContentHeaderStyled from '../../../components/ContentHeader';
import HoverableCard from '../../../components/HoverableCard';
import PaginationStyled from '../../../components/Pagination';
import { ReactComponent as ChartIcon } from '../../../images/icons/chart-bar.svg';

interface Props {
  className?: string;
}

const MapsPage = ({ className }: Props) => (
  <div className={className}>
    <div className="content">
      <ContentHeaderStyled
        className="breadcrumbs"
        title="Maps"
        breadcrumbs={[
          { label: 'Data Vizualization', url: '/data-vizualization' },
          { label: 'Maps Chart' },
        ]}
      />

      <div className="cards">
        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Map - Choropleth"
          url="test"
          image="/images/data-viz-maps/map-choropleth.png"
          icon={<ChartIcon />}
          tags={['comparison']}
        />
        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Map - Bubbles"
          url="test"
          image="/images/data-viz-maps/map-bubbles.png"
          icon={<ChartIcon />}
          tags={['comparison']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Map - Pikes"
          url="test"
          image="/images/data-viz-maps/map-pikes.png"
          icon={<ChartIcon />}
          tags={['comparison']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Map - Scatter"
          url="test"
          image="/images/data-viz-maps/map-scatter.png"
          icon={<ChartIcon />}
          tags={['correlation']}
        />
      </div>

      <PaginationStyled
        previousLink="/data-vizualization/bars"
        previous="Bars"
        nextLink="/data-vizualization/tables"
        next="Tables"
        className="footer-pagination"
      />
    </div>
  </div>
);

MapsPage.defaultProps = {
  className: '',
};

const StyledMapsPage = styled(MapsPage)`
  > .sidebar {
    overflow-x: hidden;
    height: calc(100vh - 4rem);
  }

  > .content {
    overflow-x: hidden;
    margin: 0 auto;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    > .breadcrumbs {
      margin-bottom: 24px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-bottom: 40px;
      }
    }

    > .cards {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 80px;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    > .footer-pagination {
      display: none;
      margin-top: auto;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: block;
      }
    }
  }
`;

export default StyledMapsPage;
