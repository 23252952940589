import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import Button from '../../../components/Button';
import ContentHeaderStyled from '../../../components/ContentHeader';
import Header from '../../../components/Header';
import PaginationStyled from '../../../components/Pagination';
import SidebarStyled from '../../../components/Sidebar';
import { ReactComponent as Download } from '../../../images/icons/download.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const FigmaLibraryStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <ContentHeaderStyled
          title="Figma Library"
          breadcrumbs={[
            { label: 'Dev Resources', url: '/dev-resources' },
            { label: 'Figma Library', url: '/' },
          ]}
        />
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-with-navigation-wrapper">
              <div className="page-content-with-navigation">
                <div className="title-with-download-button">
                  <h1 className="h1" id="data-components">
                    Data Visualisation Components
                  </h1>
                  <Button size="M" iconAfter={<Download />}>
                    Download Figma Library
                  </Button>
                </div>
                <div className="page-content-item mb-40">
                  <p className="font-sm">
                    Get to know the basics of accessible design before diving into Material
                    accessibility specs, patterns, and platform-specific guidance.
                  </p>
                </div>
                <img
                  src="/images/dev-resources-figma-library/figma-library-1@3x.jpg"
                  alt="Figma Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <h3 className="h3" id="kit-overview">
                    Kit overview
                  </h3>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">1</span>
                    <p>
                      <strong>The Figma frame has dimensions 1280px by 720px,</strong> the correct
                      size for PowerBI dashboard.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-figma-library/figma-library-2@3x.jpg"
                  alt="Figma Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <h3 className="visually-hidden" id="charts-and-components-settings">
                    Charts settings
                  </h3>
                  <h3 className="h3">Charts & components settings</h3>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">2</span>
                    <p>
                      <strong>Add dynamic visualizations, interactive elements,</strong> and
                      personalized insights to create a truly immersive data experience:
                    </p>
                    <ol className="disc">
                      <li>Insert charts, graphs, and tables to visualize data.</li>
                      <li>
                        Incorporate slicers, filters, and drill-down features for interactivity.
                      </li>
                      <li>
                        Include text boxes or annotations to provide insights or explanations.
                      </li>
                    </ol>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-figma-library/figma-library-3@3x.jpg"
                  alt="Figma Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">3</span>
                    <p>
                      <strong>Add dynamic visualizations, interactive elements,</strong> and
                      personalized insights to create a truly immersive data experience:
                    </p>
                    <ol className="disc">
                      <li>Insert charts, graphs, and tables to visualize data.</li>
                      <li>
                        Incorporate slicers, filters, and drill-down features for interactivity.
                      </li>
                      <li>
                        Include text boxes or annotations to provide insights or explanations.
                      </li>
                    </ol>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-figma-library/figma-library-4@3x.jpg"
                  alt="Figma Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">4</span>
                    <p>
                      <strong>Add dynamic visualizations, interactive elements,</strong> and
                      personalized insights to create a truly immersive data experience:
                    </p>
                    <ol className="disc">
                      <li>Insert charts, graphs, and tables to visualize data.</li>
                      <li>
                        Incorporate slicers, filters, and drill-down features for interactivity.
                      </li>
                      <li>
                        Include text boxes or annotations to provide insights or explanations.
                      </li>
                    </ol>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-figma-library/figma-library-5@3x.jpg"
                  alt="Figma Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <h3 className="h3" id="typography-styles">
                    Typography Styles
                  </h3>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">5</span>
                    <p>
                      <strong>Add dynamic visualizations, interactive elements,</strong> and
                      personalized insights to create a truly immersive data experience:
                    </p>
                    <ol className="disc">
                      <li>Insert charts, graphs, and tables to visualize data.</li>
                      <li>
                        Incorporate slicers, filters, and drill-down features for interactivity.
                      </li>
                      <li>
                        Include text boxes or annotations to provide insights or explanations.
                      </li>
                    </ol>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-figma-library/figma-library-6@3x.jpg"
                  alt="Figma Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <h3 className="h3" id="connecting-figma-kit">
                    Connecting Figma Kit
                  </h3>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">6</span>
                    <p>
                      <strong>Add dynamic visualizations, interactive elements,</strong> and
                      personalized insights to create a truly immersive data experience:
                    </p>
                    <ol className="disc">
                      <li>Insert charts, graphs, and tables to visualize data.</li>
                      <li>
                        Incorporate slicers, filters, and drill-down features for interactivity.
                      </li>
                      <li>
                        Include text boxes or annotations to provide insights or explanations.
                      </li>
                    </ol>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-figma-library/figma-library-7@3x.jpg"
                  alt="Figma Library"
                  className="img-responsive"
                />
              </div>
              <div>
                <div className="page-navigation">
                  <h4>Contents</h4>
                  <Scrollspy
                    ids={[
                      'data-components',
                      'kit-overview',
                      'charts-and-components-settings',
                      'typography-styles',
                      'connecting-figma-kit',
                    ]}
                    offset={0}
                    activeItemClassName="active"
                    itemContainerClassName="multilevel-link-list"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaginationStyled previousLink="/dev-resources/esrikit" previous="ESRI Library" />
      </div>
    </div>
  </div>
);

FigmaLibraryStructure.defaultProps = {
  className: '',
};

const FigmaLibrary = styled(FigmaLibraryStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 3, 4, 5])}
`;

export default FigmaLibrary;
