import styled from 'styled-components';

import ChartSettingsSelect from '../../screens/DataVizualization/components/ChartSettingsSelect';
import LibrariesSelect from '../../screens/DataVizualization/components/LibrariesSelect';
import Legend from '../Legend';
import { usePageActions } from '../PageActions/context';
import ZoomImage from '../ZoomImage';

interface Props {
  className?: string;
  imageLink: string;
}

const selectData = [
  { label: 'Kit 1', value: 'kit1' },
  { label: 'Kit 2', value: 'kit2' },
  { label: 'Kit 3', value: 'kit3' },
  { label: 'Kit 4', value: 'kit4' },
];

const ChartCard = ({ className, imageLink }: Props) => {
  const { isFullScreen } = usePageActions();
  return (
    <div className={className}>
      {!isFullScreen && (
        <div className="chart-header">
          <div>
            <p>
              Please select a kit from the dropdown menu to change the chart’s view and access the
              appropriate kit page.
            </p>
            <p>
              Follow the provided instructions to download the kit, locate your plot, and complete
              the installation process.
            </p>
          </div>
          <LibrariesSelect />
        </div>
      )}
      <div className="select-legend-container">
        <ChartSettingsSelect placeholder="Active Chart Settings" data={selectData} />
        <Legend
          type="categorical"
          data={[
            { label: 'Kit 1', color: 'red' },
            { label: 'Kit 2', color: 'blue' },
          ]}
        />
      </div>
      <ZoomImage imgSrc={imageLink} />
    </div>
  );
};

ChartCard.defaultProps = {
  className: '',
};

const ChartCardStyled = styled(ChartCard)`
  border: 1px solid ${({ theme }) => theme.colors.neutral.border};
  border-radius: 6px;
  margin-bottom: 52px;
  padding-bottom: 5px;

  .chart-header {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    background: var(--747c8914, #747c8914);
    border-bottom: 0.0625rem solid var(--dadce0, #dadce0);

    p {
      max-width: 43.75rem;
      color: var(--36404d, #36404d);
      margin: 0;
      font-size: 0.75rem;
      line-height: 1.333333333333333;

      strong {
        font-weight: 500;
      }
    }
  }

  .select-legend-container {
    display: flex;
    justify-content: space-between;
    padding: 0 35px;
    margin-top: 24px;
    margin-bottom: 34px;
  }
`;

export default ChartCardStyled;
