import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import Button from '../../../components/Button';
import ContentHeaderStyled from '../../../components/ContentHeader';
import Header from '../../../components/Header';
import PaginationStyled from '../../../components/Pagination';
import SidebarStyled from '../../../components/Sidebar';
import { ReactComponent as Download } from '../../../images/icons/download.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const ESRIKitStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <ContentHeaderStyled
          title="ESRI Library"
          breadcrumbs={[
            { label: 'Dev Resources', url: '/dev-resources' },
            { label: 'ESRI Library', url: '/' },
          ]}
        />
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-with-navigation-wrapper">
              <div className="page-content-with-navigation">
                <div className="title-with-download-button">
                  <h1 className="h1" id="downloading-ArcGIS-Pro">
                    Downloading ArcGIS Pro
                  </h1>
                  <Button iconAfter={<Download />} size="M">
                    Download ESRI Library
                  </Button>
                </div>
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">1</span>
                    <p>
                      <strong>Use a CSV file for your data set.</strong> Prepare your XY coordinate
                      data, ensure that your data set is formatted or saved as a CSV (Comma
                      Separated Values) file.
                    </p>
                  </div>
                  <div className="pseudo-list mb-40">
                    <span className="pseudo-list-nr">2</span>
                    <p>Go to the download tab and click ArcGIS Pro.</p>
                    <p>
                      Note: ArcGIS Pro license should be subscribed to be able to download ArcGIS
                      Pro
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-1@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">3</span>
                    <p>Click download in front of ArcGIS Pro</p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-2@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">4</span>
                    <p>
                      Browse to the location where you downloaded ArcGIS Pro. Double-click the
                      executable (.exe) file to start the installation process.
                    </p>
                  </div>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">5</span>
                    <p>
                      Accept the destination folder to which files are extracted or click Browse to
                      browse to a different folder. The default destination folder is{' '}
                      <strong>
                        &lt;System Drive&gt;\Users\&lt;username&gt;\Documents\ArcGIS Pro
                        &lt;version&gt;.
                      </strong>
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-3@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">6</span>
                    <p>
                      Click <strong>Next.</strong> A message informs you when the files have been
                      successfully extracted. The Launch the setup program check box is checked by
                      default.
                    </p>
                  </div>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">7</span>
                    <p>
                      Click <strong>Close</strong>
                    </p>
                  </div>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">8</span>
                    <p>
                      On the <strong>ArcGIS Pro Setup</strong> splash screen, click{' '}
                      <strong>Next.</strong>
                    </p>
                  </div>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">9</span>
                    <p>
                      Under <strong>Welcome to the ArcGIS Pro Setup program</strong>, read the
                      information and take recommended actions. Click Next.
                    </p>
                  </div>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">10</span>
                    <p>
                      Review the <strong className="font-xs">master agreement</strong> and choose an
                      option:
                    </p>
                    <ol className="lower-latin">
                      <li>
                        If you agree to the terms, click the option to accept the agreement and
                        click Next.
                      </li>
                      <li>
                        If you do not agree to the terms, click Cancel to exit the installation.
                      </li>
                    </ol>
                  </div>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">11</span>
                    <p>Choose an installation context option:</p>
                    <ol className="lower-latin">
                      <li>
                        Click <span className="font-medium">Anyone who uses this computer</span>{' '}
                        (the default) for a per-machine installation.
                      </li>
                      <li>
                        Click <strong>Only for me</strong> for a per-user installation
                      </li>
                    </ol>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-4@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">12</span>
                    <p>
                      Click <strong>Next.</strong>
                    </p>
                  </div>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">13</span>
                    <p>
                      Accept the default location based on your installation context option, or
                      click
                      <strong>Change</strong> to specify a different folder.
                    </p>
                  </div>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">14</span>
                    <p>
                      Click <strong>Next.</strong>
                    </p>
                  </div>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">15</span>
                    <p>
                      15 Under <strong>Ready to Install the Program,</strong> the option to
                      participate in the Esri User Experience Improvement program is checked by
                      default. Uncheck the box if you don&apos;t want to participate. (You can
                      change your participation status at any time after ArcGIS Pro is installed.)
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-5@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">16</span>
                    <p>
                      Click <strong>Install.</strong>
                    </p>
                  </div>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">17</span>
                    <p>
                      When the installation is completed, the <strong>Run ArcGIS Pro now</strong>{' '}
                      option is checked by default. Uncheck the box if you don&apos;t want to start
                      the application immediately.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-6@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list mb-40">
                    <span className="pseudo-list-nr">18</span>
                    <p>
                      Click <strong>Finish.</strong>
                    </p>
                  </div>
                  <h3 id="configuring-ArcGIS-Pro-License" className="mb-40 h3">
                    Configuring ArcGIS Pro License
                  </h3>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">1</span>
                    <p>Run ArcGIS Pro</p>
                  </div>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">2</span>
                    <p>
                      <strong>Log in</strong> Screen will appear
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-7@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list mb-40">
                    <span className="pseudo-list-nr">3</span>
                    <p>Sign in with ArcGIS Login</p>
                  </div>
                  <h3 id="creating-map" className="mb-40 h3">
                    Creating Map
                  </h3>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">1</span>
                    <p>
                      Run ArcGIS Pro and <strong>create a new project</strong> by clicking on Map
                      under New Project. Give a name for the new project and select the default
                      location or browse to a new location where you want to save the project. Click
                      ok.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-8@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">2</span>
                    <p>
                      From the view tab go to the catalog pane. A new database is created under the
                      database folder.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-9@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">3</span>
                    <p>
                      <strong>Click portal</strong> in catalog pane and then click ArcGIS Online
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-10@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">4</span>
                    <p>
                      <strong>Search for data</strong> e.g Florida county and it will show all
                      freely available data. Click and add to map
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-11@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">5</span>
                    <p>
                      Right click on the exported layer and{' '}
                      <strong>click Data -&gt; Export features</strong> to export it into local
                      database.
                    </p>
                    <p>
                      Note: It depends on the permissions on the data if it is allowed to be
                      exported to a local database or not. The county boundaries can also be
                      downloaded from different sources on the web such as state government websites
                      and data warehouses.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-12@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">6</span>
                    <p>
                      <strong>Keep the layer</strong> from the local database in the table of
                      content and remove the other layer.
                    </p>
                  </div>
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">7</span>
                    <p>
                      Right click on county layer and click <strong>Join and Relates → Join</strong>
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-13@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">8</span>
                    <p>
                      <strong>Select the CSV</strong> file and select primary keys in the two data
                      sources.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-14@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">9</span>
                    <p>
                      <strong>Click add data</strong> and select the csv file. Right click on the
                      newly added layer and click Create Point from Table → XY Table to Point.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-15@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">10</span>
                    <p>
                      Give the <strong>name of layer</strong> ‘Physicians’ in output database
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-16@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">11</span>
                    <p>
                      Right click on counties layer and <strong>click symbology</strong> and give
                      the following specifications
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-17@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">12</span>
                    <p>
                      Right click on counties layer and click symbology and give the{' '}
                      <strong>following specifications</strong>
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-18@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">13</span>
                    <p>
                      Copy and <strong>paste the Physicians layer</strong> in the table of content
                      and rename it to hospitals. Apply symbology as follows.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-19@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">14</span>
                    <p>
                      <strong>Repeat the previous</strong> step and rename the layer to
                      Male_Female_Population. Apply symbology as follows.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-esrikit/downloading-arcgis-pro-20@3x.jpg"
                  alt="Downloading ArcGIS Pro"
                  className="img-responsive"
                />
              </div>
              <div>
                <div className="page-navigation">
                  <h4>Contents</h4>
                  <Scrollspy
                    ids={[
                      'downloading-ArcGIS-Pro',
                      'configuring-ArcGIS-Pro-License',
                      'creating-map',
                    ]}
                    offset={0}
                    activeItemClassName="active"
                    itemContainerClassName="multilevel-link-list"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaginationStyled
          previousLink="/dev-resources/excel-library"
          previous="Excel Library"
          nextLink="/dev-resources/figma-library"
          next="Figma Library"
        />
      </div>
    </div>
  </div>
);

ESRIKitStructure.defaultProps = {
  className: '',
};

const ESRIKit = styled(ESRIKitStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 3])}
`;

export default ESRIKit;
