import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LayoutWithHeader from './components/LayoutWithHeader';
import LayoutWithSidebar from './components/LayoutWithSidebar';
import { PageActionsProvider } from './components/PageActions/context';
import {
  Accessibility,
  BarChartGrouped,
  BrandingStyleguide,
  Buttons,
  ChartMatch,
  Colors,
  Communication,
  Containment,
  Content,
  DashboardTemplates,
  DataVizualization,
  DevResources,
  ESRIKit,
  Error404,
  ExcelLibrary,
  FigmaLibrary,
  FormElements,
  GeneralComponents,
  GetStarted,
  Icons,
  Imagery,
  InteractionsAndAnimations,
  LineChart,
  Links,
  Logos,
  MainPage,
  MapChloropleth,
  Navigation,
  PowerBIKit,
  Selection,
  Table,
  Typography,
  VoiceAndTone,
  OverviewPage,
  BuilderPage,
  BarsPage,
  LinesPage,
  MapsPage,
  TablesPage,
  CircularsPage,
  DiagramsPage,
  OthersPage,
  AreaChart,
  StackedAreaChart,
  BarChartV,
  BarChartH,
  GroupedBarChartV,
  GroupedBarChartH,
  StackedBarChartV,
  StackedBarChartH,
  LollipopV,
  LollipopH,
  GroupedLollipopV,
  GroupedLollipopH,
  StackedLollipopV,
  StackedLollipopH,
  FunnelChart,
  HistogramChart,
} from './screens';

const App = () => (
  <Routes>
    <Route path="/" element={<MainPage />} />
    <Route element={<LayoutWithHeader />}>
      <Route path="/overview" element={<OverviewPage />} />
      <Route path="/builder" element={<BuilderPage />} />
    </Route>
    <Route path="/get-started" element={<GetStarted />} />
    <Route
      element={
        <PageActionsProvider>
          <LayoutWithSidebar />
        </PageActionsProvider>
      }
    >
      <Route path="/data-vizualization" element={<DataVizualization />} />
      <Route path="/data-vizualization/lines" element={<LinesPage />} />
      <Route path="/data-vizualization/bars" element={<BarsPage />} />
      <Route path="/data-vizualization/maps" element={<MapsPage />} />
      <Route path="/data-vizualization/tables" element={<TablesPage />} />
      <Route path="/data-vizualization/circulars-chart" element={<CircularsPage />} />
      <Route path="/data-vizualization/diagrams" element={<DiagramsPage />} />
      <Route path="/data-vizualization/others" element={<OthersPage />} />
      <Route path="/data-vizualization/lines/line-chart" element={<LineChart />} />
      <Route path="/data-vizualization/lines/area-chart" element={<AreaChart />} />
      <Route path="/data-vizualization/lines/stacked-area-chart" element={<StackedAreaChart />} />
      <Route path="/data-vizualization/bars/chart-v" element={<BarChartV />} />
      <Route path="/data-vizualization/bars/chart-h" element={<BarChartH />} />
      <Route path="/data-vizualization/bars/grouped-bar-chart-v" element={<GroupedBarChartV />} />
      <Route path="/data-vizualization/bars/grouped-bar-chart-h" element={<GroupedBarChartH />} />
      <Route path="/data-vizualization/bars/stacked-bar-chart-v" element={<StackedBarChartV />} />
      <Route path="/data-vizualization/bars/stacked-bar-chart-h" element={<StackedBarChartH />} />
      <Route path="/data-vizualization/bars/lollipop-v" element={<LollipopV />} />
      <Route path="/data-vizualization/bars/lollipop-h" element={<LollipopH />} />
      <Route path="/data-vizualization/bars/grouped-lollipop-v" element={<GroupedLollipopV />} />
      <Route path="/data-vizualization/bars/grouped-lollipop-h" element={<GroupedLollipopH />} />
      <Route path="/data-vizualization/bars/stacked-lollipop-v" element={<StackedLollipopV />} />
      <Route path="/data-vizualization/bars/stacked-lollipop-h" element={<StackedLollipopH />} />
      <Route path="/data-vizualization/bars/funnel-chart" element={<FunnelChart />} />
      <Route path="/data-vizualization/bars/histogram" element={<HistogramChart />} />
    </Route>
    <Route path="/data-vizualization/maps/map-chloropleth" element={<MapChloropleth />} />
    <Route path="/data-vizualization/bars/bar-chart-grouped" element={<BarChartGrouped />} />
    <Route path="/get-started/accessibility" element={<Accessibility />} />
    <Route path="/get-started/content" element={<Content />} />
    <Route path="/get-started/voice-and-tone" element={<VoiceAndTone />} />
    <Route path="/branding-styleguide" element={<BrandingStyleguide />} />
    <Route path="/branding-styleguide/colors" element={<Colors />} />
    <Route path="/branding-styleguide/typography" element={<Typography />} />
    <Route path="/branding-styleguide/logos" element={<Logos />} />
    <Route path="/branding-styleguide/icons" element={<Icons />} />
    <Route path="/branding-styleguide/imagery" element={<Imagery />} />
    <Route
      path="/branding-styleguide/interactions-and-animations"
      element={<InteractionsAndAnimations />}
    />
    <Route path="/general-components" element={<GeneralComponents />} />
    <Route path="/general-components/buttons" element={<Buttons />} />
    <Route path="/general-components/links" element={<Links />} />
    <Route path="/general-components/form-elements" element={<FormElements />} />
    <Route path="/general-components/communication" element={<Communication />} />
    <Route path="/general-components/navigation" element={<Navigation />} />
    <Route path="/general-components/selection" element={<Selection />} />
    <Route path="/general-components/table" element={<Table />} />
    <Route path="/general-components/containment" element={<Containment />} />
    <Route path="/dashboard-templates" element={<DashboardTemplates />} />
    <Route path="/dev-resources" element={<DevResources />} />
    <Route path="/dev-resources/esrikit" element={<ESRIKit />} />
    <Route path="/dev-resources/powerbikit" element={<PowerBIKit />} />
    <Route path="/dev-resources/excel-library" element={<ExcelLibrary />} />
    <Route path="/dev-resources/figma-library" element={<FigmaLibrary />} />
    <Route path="/design-system/chart-match" element={<ChartMatch />} />

    <Route path="*" element={<Error404 />} />
  </Routes>
);

const Router: FC = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default Router;
