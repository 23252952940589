import { ReactNode } from 'react';
import styled from 'styled-components';

import { ReactComponent as BuildIcon } from '../../images/icons/build-chart.svg';
import { ReactComponent as ComparisonIcon } from '../../images/icons/chartmatch.svg';
import { ReactComponent as CorrelationIcon } from '../../images/icons/charts-comparison.svg';
import { ReactComponent as DistributionIcon } from '../../images/icons/charts-distribution.svg';
import { ReactComponent as TrendIcon } from '../../images/icons/charts-trend.svg';
import { ReactComponent as ChevronDown } from '../../images/icons/chevron-down.svg';
import { ReactComponent as ProportionIcon } from '../../images/icons/proportion.svg';
import Button from '../Button';

type Tag = 'proportion' | 'comparison' | 'distribution' | 'correlation' | 'trend';

interface Props {
  className?: string;
  image: string;
  icon: ReactNode;
  title: string;
  url: string;
  description: string;
  tags: Tag[];
}

const TREND_ICONS: Record<Tag, ReactNode> = {
  trend: (
    <div>
      <TrendIcon /> Trend
    </div>
  ),
  correlation: (
    <div>
      <CorrelationIcon /> Correlation
    </div>
  ),
  distribution: (
    <div>
      <DistributionIcon /> Distribution
    </div>
  ),
  comparison: (
    <div>
      <ComparisonIcon /> Comparison
    </div>
  ),
  proportion: (
    <div>
      <ProportionIcon /> Proportion
    </div>
  ),
};

const getIcon = (tags: Tag[]) => tags.map((tag) => TREND_ICONS[tag]);

const HoverableCard = ({ className, image, icon, title, url, description, tags }: Props) => (
  <div className={className}>
    <div className="chart-block">
      <div className="chart-preview">
        <img className="chart-img img-responsive" src={image} alt="" />
        <div className="chart-title">
          <div className="icon-container">{icon}</div>
          {title}
        </div>
      </div>
      <div className="chart-details">
        <div className="chart-title">
          <div className="icon-container">{icon}</div>
          {title}
        </div>
        <div className="chart-description">
          <div className="top-buttons">
            <div className="type-badges">{getIcon(tags)}</div>
          </div>
          {description}
        </div>
        <div className="chart-buttons">
          <a href={url} title="">
            Details
          </a>
          <div className="right">
            <Button className="reset-filters" size="S" onClick={() => {}} iconAfter={<BuildIcon />}>
              Build
            </Button>
            <Button
              iconAfter={<ChevronDown />}
              variant="primary"
              className="reset-filters"
              size="S"
              onClick={() => {}}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

HoverableCard.defaultProps = {
  className: '',
};

const StyledHoverableCard = styled(HoverableCard)`
  .chart-block {
    border-radius: 8px;
    overflow: hidden;
    background: transparent;
    position: relative;
    border: 1px solid ${(props) => props.theme.colors.neutral.border};

    > .chart-preview {
      position: relative;
      transform: translateY(0);
      transition: transform 0.2s ease-in-out;
    }

    &:hover {
      .chart-preview {
        transform: translateY(-100%);
      }

      .chart-details {
        top: 0;
      }
    }

    .type-badges {
      font-size: 0.625rem;
      line-height: 0.875rem;
      display: flex;
      align-items: center;
      gap: 8px;

      > div {
        color: ${(props) => props.theme.colors.neutral.text};
        border-radius: 4px;
        background: ${(props) => props.theme.colors.neutral.background};
        padding: 5px 6px;
        display: flex;
        align-items: center;

        > svg {
          padding-right: 0.375rem;
          margin-right: 0.375rem;
          border-right: 1px solid ${(props) => props.theme.colors.neutral.border};
          color: ${(props) => props.theme.colors.neutral['text-weak']};
          width: 1.25rem;
        }
      }
    }

    .chart-details {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 100%;
      transition: top 0.2s ease-in-out;
      height: 100%;
      width: 100%;
      z-index: 10;
      background: linear-gradient(to left, white, rgba(255, 255, 255, 0)), ${(props) => props.theme.colors.neutral.background};);

      .chart-title {
        padding: 12px 12px 0;
        background: transparent;
        color: ${(props) => props.theme.colors.neutral.text};
        font-size: ${(props) => props.theme.fontSizes.base};
        line-height: ${(props) => props.theme.lineHeights.base};
        font-weight: 400;

        .icon-container {
          color: white;
          background-color: ${(props) => props.theme.colors.primary.border};
        }
      }

      .chart-description {
        padding: 0 12px;
        margin-bottom: 16px;
        margin-top: 8px;
        flex: 1;
        color: ${(props) => props.theme.colors.neutral['text-weak']};
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
        font-size: ${(props) => props.theme.fontSizes.xs};
        line-height: ${(props) => props.theme.lineHeights.xs};

        .top-buttons {
          display: flex;
          gap: 0.5rem;
          margin-bottom: 8px;
        }
      }

      .chart-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px 12px;

        .right {
          display: flex;
          gap: 0.5rem;
        }

        a {
          font-weight: 500;
          font-size: 0.875rem;
          color: ${(props) => props.theme.colors.primary.text};
          text-decoration: none;
        }
      }
    }

    .chart-img {
      width: 83%;
      height: auto;
      display: block;
      margin: 0 auto 0.5rem;
      padding-top: 5px;
    }

    .chart-title {
      background-color: ${(props) => props.theme.colors.neutral.background};
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.colors.neutral.text};
      font-size: ${(props) => props.theme.fontSizes.base};
      line-height: ${(props) => props.theme.lineHeights.base};
      font-weight: 400;
      padding: 8px 12px;

      .icon-container {
        margin-right: 0.5rem;
        color: ${(props) => props.theme.colors.primary.border};
        padding: 0.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0rem 0.125rem 0.25rem 0.0625rem #141c2c0a;
        background: white;
        border-radius: 0.25rem;
      }
    }
  }
`;

export default StyledHoverableCard;
