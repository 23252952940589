import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import Button from '../../../components/Button';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import Header from '../../../components/Header';
import NotificationStyled from '../../../components/Notification';
import PaginationStyled from '../../../components/Pagination';
import SidebarStyled from '../../../components/Sidebar';
import { ReactComponent as IconAccessibility } from '../../../images/icons/accessibility-56.svg';
import { ReactComponent as Download } from '../../../images/icons/download.svg';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as InfoCircle } from '../../../images/icons/info-circle.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government Usability Testing Resources:',
    cards: [
      {
        title: 'The Section 508 accessibility program',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility for Teams guide',
        subtitle: '[accessibility.digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Getting started with usability testing',
        subtitle: '[digital.gov]',
        linkUrl: '#',
      },
    ],
  },
];

const LogosStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <ContentHeaderStyled
          title="Logos"
          breadcrumbs={[
            { label: 'Branding Styleguide', url: '/branding-styleguide' },
            { label: 'Logos' },
          ]}
        />
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-with-navigation-wrapper">
              <div className="page-content-with-navigation">
                <div className="page-content-item">
                  <h1 className="h1" id="overview">
                    Overview
                  </h1>
                  <p className="mb-40">
                    The Department of Health Care Services (DHCS) communicates to the public about
                    its various programs and services. Whether in print or online, all materials
                    should align with the design guidelines outlined in this document.
                  </p>
                  <NotificationStyled
                    icon={<InfoCircle />}
                    title={
                      <div>
                        Are you looking to download logos? Find them in{' '}
                        <a href="/">Download Assets.</a>
                      </div>
                    }
                    color="blue"
                    className="mb-40"
                  />
                  <h3 className="h3" id="main-variations">
                    Main variations
                  </h3>
                  <p className="mb-40">
                    DHCS has two distinct logos. Please refer to this page to understand when to use
                    each.
                  </p>
                </div>
                <div className="number-cards">
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">1</span>
                      <img
                        src="/images/branding-styleguide-logos/logos-main-variations-01@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Official logo.</strong>
                      <p>
                        The default button state is how a button appears or behaves on a user
                        interface before any interaction.
                      </p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">2</span>
                      <img
                        src="/images/branding-styleguide-logos/logos-main-variations-02@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Programs and services logo</strong>
                      <p>Utilize this when showcasing programs and services aimed at consumers.</p>
                      <p>
                        The Office of Communications (OC) Design Team develops these logos upon
                        request. To obtain one for your program, please contact the OC Design Team
                        via email.
                      </p>
                    </div>
                  </div>
                </div>
                <h1 className="divider h1" id="specifications">
                  Specifications
                </h1>
                <div className="page-content-item">
                  <h3 className="h3" id="department-logo">
                    Department Logo
                  </h3>
                  <p className="mb-40">
                    This represents the official logo of the Department. Use it when referencing the
                    entire Department.
                  </p>
                  <NotificationStyled
                    icon={<InfoCircle />}
                    title="Avoid using the logo multiple times on a single page in your document."
                    color="blue"
                    className="mb-40"
                  />
                  <h4 className="h4">Monochrome Logo</h4>
                  <p className="mb-40">
                    The logos displayed here are monochrome versions of the Department&apos;s logo.
                    For lighter backgrounds, opt for the full-color emblem. If visibility is an
                    issue, switch to the black variant.
                    <br />
                    On darker backgrounds, employ the white logos provided on the left. The white
                    emblem with added color, as detailed on the following page, can be utilized upon
                    receiving the green light from the OC Design Team.
                  </p>
                </div>
                <div className="number-cards">
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">1</span>
                      <img
                        src="/images/branding-styleguide-logos/logos-monochrome-logo-01@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Official logo.</strong>
                      <p>
                        The default button state is how a button appears or behaves on a user
                        interface before any interaction.
                      </p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">2</span>
                      <img
                        src="/images/branding-styleguide-logos/logos-monochrome-logo-02@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Variation</strong>
                      <p>Utilize this when showcasing programs and services aimed at consumers. </p>
                      <p>
                        The Office of Communications (OC) Design Team develops these logos upon
                        request. To obtain one for your program, please contact the OC Design Team
                        via email.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="page-content-item mb-40">
                  <h4 className="h4">Advanced Logo Options</h4>
                  <p>
                    This page provides advanced color options for logos editable in design software.
                    Use these logos judiciously, considering the color contrast betweenthe logo and
                    its background.
                  </p>
                </div>
                <div className="number-cards">
                  <div className="number-card">
                    <div className="number-card-img">
                      <img
                        src="/images/branding-styleguide-logos/logos-advanced-logo-01@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Official logo.</strong>
                      <p>
                        The default button state is how a button appears or behaves on a user
                        interface before any interaction.
                      </p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <img
                        src="/images/branding-styleguide-logos/logos-advanced-logo-02@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Variation</strong>
                      <p>Utilize this when showcasing programs and services aimed at consumers.</p>
                      <p>
                        The Office of Communications (OC) Design Team develops these logos upon
                        request. To obtain one for your program, please contact the OC Design Team
                        via email.
                      </p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <img
                        src="/images/branding-styleguide-logos/logos-advanced-logo-03@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Images</strong>
                      <p>
                        Use this logo on dark images, ensuring the background doesn&apos;t clash
                        with the oranges or blues in the letter &apos;D&apos;.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="page-content-item">
                  <NotificationStyled
                    icon={<InfoCircle />}
                    title={
                      <div>
                        If unsure, check your logo-background color contrast
                        <br />
                        on WebAIM’s online color contrast checker.
                      </div>
                    }
                    color="blue"
                    className="mb-40"
                  />
                  <h4 className="h4">Usage: Spacing</h4>
                  <p className="mb-40">
                    DHCS logos should never be crowded by other items. Follow these minimum spacing
                    rules when the logo is used
                  </p>
                </div>
                <img
                  src="/images/branding-styleguide-logos/dhcs-logos-usage-spacing@3x.jpg"
                  alt=""
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <h4 className="h4">Usage: Restrictions</h4>
                  <p className="mb-40">
                    Do not alter the DHCS logo, program logo, or state seal beyond the guidelines
                    provided. Use approved designs to preserve DHCS&apos;s brand integrity.
                  </p>
                </div>
                <div className="ex-cards">
                  <div className="ex-card false">
                    <img
                      className="ex-card-img"
                      src="/images/branding-styleguide-logos/screen-logos-usage-restrictions-01@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>
                        Do NOT distort, alter, or modify the logo&apos;s attributes, including
                        boldness.
                      </p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      src="/images/branding-styleguide-logos/screen-logos-usage-restrictions-02@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>
                        Do NOT alter the logo&apos;s colors beyond this guide&apos;s allowances or
                        add any outlines, shadows, or glows.
                      </p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      src="/images/branding-styleguide-logos/screen-logos-usage-restrictions-03@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>
                        Do NOT modify components (e.g., don&apos;t use the poppy alone); use only
                        logos from this document or those supplied by the OC Design Team.
                      </p>
                    </div>
                  </div>
                </div>
                <h3 className="divider h3" id="program-logos">
                  Program Logos
                </h3>
                <div className="page-content-item">
                  <p className="mb-40">
                    This is a secondary DHCS logo designated for consumer-facing programs and
                    initiatives. Its consistent visual identity links programs together and anchors
                    them to DHCS.
                  </p>
                </div>
                <div className="number-cards">
                  <div className="number-card">
                    <div className="number-card-img">
                      <img
                        src="/images/branding-styleguide-logos/logos-cal-hope@3x.jpg"
                        alt="CalHOPE"
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>CalHOPE</strong>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <img
                        src="/images/branding-styleguide-logos/logos-medi-cal@3x.jpg"
                        alt="Medi-Cal"
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Medi-Cal</strong>
                    </div>
                  </div>
                </div>
                <h4 className="divider h4">Color Options</h4>
                <div className="page-content-item mb-40">
                  <p>
                    DHCS logos should never be crowded by other items.
                    <br />
                    Follow these minimum spacing rules when the logo is used
                  </p>
                </div>
                <div className="number-cards">
                  <div className="number-card">
                    <div className="number-card-img">
                      <img
                        src="/images/branding-styleguide-logos/logos-dark-colors@3x.jpg"
                        alt="Dark Colors"
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Dark Colors</strong>
                      <p>
                        On DHCS Blue*, Teal*, or black, the text turns white while the poppy color
                        remains unchanged.
                      </p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <img
                        src="/images/branding-styleguide-logos/logos-dhcs-yellow@3x.jpg"
                        alt="DHCS Yellow"
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>DHCS Yellow</strong>
                      <p>
                        On DHCS Yellow*, the poppy turns white, the text remains, and the
                        flower&apos;s center reveals the background.
                      </p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <img
                        src="/images/branding-styleguide-logos/logos-multiple-color-images@3x.jpg"
                        alt="Multiple-Color Images"
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Multiple-Color Images</strong>
                      <p>
                        For multi-color images, use discernment. Select a logo based on visibility.
                        The full-color logo may fit if colors don&apos;t clash and contrast is
                        effective.
                      </p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <img
                        src="/images/branding-styleguide-logos/logos-dhcs-yellow-02@3x.jpg"
                        alt="DHCS Yellow"
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>DHCS Yellow</strong>
                      <p>
                        On DHCS Yellow*, the poppy turns white, the text remains, and the
                        flower&apos;s center reveals the background.
                      </p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <img src="/images/branding-styleguide-logos/logos-black@3x.jpg" alt="Black" />
                    </div>
                    <div className="number-card-desc">
                      <strong>Black</strong>
                      <p>The program logo cannot be used in all-black.</p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <img
                        src="/images/branding-styleguide-logos/logos-grayscale@3x.jpg"
                        alt="Grayscale"
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Grayscale</strong>
                      <p>
                        For non-color printing, use this version: the flower is 50% gray, and the
                        text is black.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="page-content-item">
                  <h4 className="h4">Usage: Restrictions</h4>
                  <p className="mb-40">
                    Like the Department logo, only modify program logos as allowed in this guide.
                    <br />
                    Use approved designs to ensure consistency and safeguard DHCS&apos;s brand
                    integrity.
                  </p>
                </div>
                <div className="ex-cards">
                  <div className="ex-card false">
                    <img
                      className="ex-card-img"
                      src="/images/branding-styleguide-logos/screen-logos-usage-restrictions-04@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>
                        Do NOT distort, alter, or modify the logo&apos;s attributes, including
                        boldness.
                      </p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      src="/images/branding-styleguide-logos/screen-logos-usage-restrictions-05@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>
                        Do NOT alter the logo&apos;s colors beyond this guide&apos;s allowances or
                        add any outlines, shadows, or glows.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ex-cards">
                  <div className="ex-card false">
                    <img
                      src="/images/branding-styleguide-logos/screen-logos-usage-restrictions-06@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>
                        Do NOT modify components (e.g., don&apos;t use the poppy alone); use only
                        logos from this document or those supplied by the OC Design Team.
                      </p>
                    </div>
                  </div>
                </div>
                <h3 id="colors" className="divider h3">
                  Colors
                </h3>
                <div className="page-content-item">
                  <p className="mb-40">
                    When using the logo on colored backgrounds, adhere to these guidelines for
                    clarity. <br />
                    Adjustments in design software might be required for diverse background colors.
                  </p>
                  <NotificationStyled
                    icon={<InfoCircle />}
                    title="Avoid using the logo multiple times on a single page in your document."
                    color="blue"
                    className="mb-40"
                  />
                </div>
                <img
                  src="/images/branding-styleguide-logos/logos-on-colored-backgrounds@3x.jpg"
                  alt=""
                  className="mb-40 img-responsive"
                />
                <div className="background-colors">
                  <div className="background-colors-item">
                    <div className="background-color" style={{ backgroundColor: `#17315a` }} />
                    <strong>DHCS</strong>
                    <p>
                      Hex <span>#17315a /</span> RGB <span>23.49.90</span>
                    </p>
                  </div>
                  <div className="background-colors-item">
                    <div className="background-color" style={{ backgroundColor: `#2d6e8d` }} />
                    <strong>DHCS Teal</strong>
                    <p>
                      Hex <span>#2d6e8d /</span> RGB <span>45.110.141</span>
                    </p>
                  </div>
                  <div className="background-colors-item">
                    <div className="background-color" style={{ backgroundColor: `#e47225` }} />
                    <strong>DHCS Orange</strong>
                    <p>
                      Hex <span>#E47225 /</span> RGB <span>228.114.37</span>
                    </p>
                  </div>
                  <div className="background-colors-item">
                    <div className="background-color" style={{ backgroundColor: `#f9a71c` }} />
                    <strong>DHCS Yellow</strong>
                    <p>
                      Hex <span>#F9A71C /</span> RGB <span>249.167.28</span>
                    </p>
                  </div>
                  <div className="background-colors-item">
                    <div className="background-color" style={{ backgroundColor: `#eceef0` }} />
                    <strong>Accent Gray</strong>
                    <p>
                      Hex <span>#ECEEF0 /</span> RGB <span>236.238.240</span>
                    </p>
                  </div>
                </div>
                <h1 id="references" className="divider h1">
                  References
                </h1>
                {dataExternalLinkGroups.map((externalLinkGroup) => (
                  <ExternalLinkGroupStyled
                    key={externalLinkGroup.id}
                    title={externalLinkGroup.title}
                    cards={externalLinkGroup.cards}
                  />
                ))}
                <div className="page-content-item">
                  <h3 className="h3" id="download-assets">
                    Download Assets
                  </h3>
                  <p>
                    All DHCS logos, including program logos, are available for download in three
                    formats: JPG, PNG, and SVG.
                  </p>
                  <div className="download-assets">
                    <div className="download-assets-info">
                      <div className="download-assets-icon">
                        <IconAccessibility />
                      </div>
                      <div className="download-assets-title">
                        <strong>Logo Package</strong>
                        <p>
                          <span>8</span> Files
                        </p>
                      </div>
                    </div>
                    <div className="download-assets-buttons">
                      <Button size="M" iconAfter={<Download />}>
                        SVG
                      </Button>
                      <Button size="M" iconAfter={<Download />}>
                        PNG
                      </Button>
                      <Button size="M" iconAfter={<Download />}>
                        JPG
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="page-navigation">
                  <h4>Contents</h4>
                  <Scrollspy
                    ids={[
                      'overview',
                      'main-variations',
                      'specifications',
                      'department-logo',
                      'program-logos',
                      'colors',
                      'references',
                      'download-assets',
                    ]}
                    offset={0}
                    activeItemClassName="active"
                    itemContainerClassName="multilevel-link-list"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaginationStyled
          previousLink="/branding-styleguide/typography"
          previous="Typography"
          nextLink="/branding-styleguide/icons"
          next="Icons"
        />
      </div>
    </div>
  </div>
);

LogosStructure.defaultProps = {
  className: '',
};

const Logos = styled(LogosStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 4, 5, 6])}
`;

export default Logos;
