import { useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import styled from 'styled-components';

import { usePageActions } from '../PageActions/context';

interface Props {
  imgSrc?: string;
  className?: string;
}

const ZoomImage = ({ imgSrc, className }: Props) => {
  const { onZoomIn, onZoomOut, onResetSettings, isFullScreen } = usePageActions();
  const [isDragging, setIsDragging] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const getCursorStyle = (isZoomed: boolean, isDragging: boolean) => {
    if (!isZoomed) return 'default';
    return isDragging ? 'grabbing' : 'grab';
  };

  return (
    <div style={{ cursor: getCursorStyle(isZoomed, isDragging) }} className={className}>
      <TransformWrapper
        wheel={{ disabled: !isZoomed }}
        initialScale={1}
        maxScale={1.5}
        onTransformed={(transform) => setIsZoomed(transform.state.scale > 1)}
        onPanningStart={() => setIsDragging(true)}
        onPanningStop={() => setIsDragging(false)}
        initialPositionX={0}
        initialPositionY={0}
        alignmentAnimation={!isZoomed ? undefined : { sizeX: 0, sizeY: 0 }}
      >
        {({ zoomIn, zoomOut, resetTransform }) => {
          onZoomIn(zoomIn);
          onZoomOut(zoomOut);
          onResetSettings(() => resetTransform());
          return (
            <TransformComponent
              contentStyle={{
                height: isFullScreen ? 'calc(100vh - 160px - 96px - 40px - 45px)' : 'auto',
              }}
            >
              <img
                onDragStart={(e) => e.preventDefault()} // Prevent default drag behavior
                src={imgSrc}
                alt="Zoomable"
                className="zoom-image"
              />
            </TransformComponent>
          );
        }}
      </TransformWrapper>
    </div>
  );
};

ZoomImage.defaultProps = {
  imgSrc: '',
  className: '',
};

const StyledZoomImage = styled(ZoomImage)`
  width: 100%;

  > .react-transform-wrapper {
    width: 100%;

    > .react-transform-component {
      width: 100%;
    }
  }

  .zoom-image {
    min-width: 100%;
    height: 100%;
    max-height: 576px;
  }
`;

export default StyledZoomImage;
