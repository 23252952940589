import styled from 'styled-components';

const Box = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'paddingBottom',
      'paddingLeft',
      'paddingRight',
      'paddingTop',
      'padding',
      'border',
      'margin',
      'marginBottom',
      'marginLeft',
      'marginRight',
      'marginTop',
      'width',
      'height',
      'backgroundColor',
      'borderRadius',
      'borderTop',
      'borderRight',
      'borderBottom',
      'borderLeft',
    ].includes(prop),
})<{
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  padding?: string;
  border?: string;
  margin?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  borderRadius?: string;
  borderTop?: string;
  borderRight?: string;
  borderBottom?: string;
  borderLeft?: string;
}>`
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom};`}
    ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft};`}
    ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight};`}
    ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop};`}
    ${({ border }) => border && `border: ${border};`}
    ${({ borderTop }) => borderTop && `border-top: ${borderTop};`}
    ${({ borderRight }) => borderRight && `border-right: ${borderRight};`}
    ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom};`}
    ${({ borderLeft }) => borderLeft && `border-left: ${borderLeft};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`}
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`}
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border-radius: ${({ borderRadius }) => borderRadius || '0'};
`;

export default Box;
